import React from 'react'
import Link from 'gatsby-link'
import ScanningContainer from '../../../components/ScanningContainer'
import CrossImpact from '../../../components/CrossImpact'
import Drivers from '../../../components/Drivers'
import { Header, Icon, List, Divider, Item, Dimmer, Loader } from 'semantic-ui-react'
import bgVid1 from '../../../assets/death-transformative-bg-v.mp4'
import './style.css'


class TransformationPage extends React.Component {
constructor(props) {
  super(props);
  // Don't call this.setState() here!
  this.state = {
      drivers: [],
      dimmer: true
  }
  this.onNextFrame = this.onNextFrame.bind(this);
  this.removeDimmer = this.removeDimmer.bind(this);
}


    componentDidMount() {
        this.onNextFrame(this.removeDimmer)

    }

    removeDimmer() {
        this.setState({dimmer: false})
    }

    onNextFrame(callback) {
    setTimeout(function () {
        window.requestAnimationFrame(callback)
    }, 0)
}

    render () {
        // const allHits = this.state.allHits;
        return (
            <div className="future-scenario-page">
                <video playsInline autoPlay muted loop poster="" id="bgvid">
                    <source src={bgVid1} type="video/mp4" />
                </video>

                <div className="future-scenario-content-wrapper">
                    <div className="section-content">
                        <Link className="neon-title" to='/scenarios'>&larr;</Link>
                        <div className="future-scenario-title-wrapper trans-logo">
                            <h2 className="neon-title neon-header">Communal Death, Techno-sustainability, and Our First Experience with Artifical Afterlife</h2>
                        </div>
                            <p>At some point in the 20s (that’s 2020’s not 1920s) death took its toll on the United States. No one was prepared for the massive wave of suicide that swept the nation. People blamed it on mental health, social media, technology, information overload, education, income equality, and every other societal woe you could think of. We certainly had no shortage of societal shortcomings. But the truth is we were willfully underprepared to thrive and be content in this integrated world we created. And as a result, we began taking our own lives in record numbers. By 2033, at its peak, if you were alive in America you knew approximately 2.3 people who had committed suicide and many more who were not only open to the suggestion but weren’t scared to talk about it. </p>
                            <p>It was awful. But it forced something inside of the collective conscious of America. It forced us to confront the inevitable. With many of these deaths came suicide messages, first on facebook, twitter, etc but then on new platforms that were tailor-made for post-life memorials; pre-made videos were released when they wanted, how they wanted, and to who they wanted. People were baring their souls before they died, telling tales of a fragmented world, where on the screen people everywhere living their best lives, pushing the envelopes of happiness, but off the screen, most people felt broken and lost, unable to reconcile how they felt with what they saw. A flood of suicides and death followed by a tidal wave of people's last messages. And those last messages left by the dying were how so many Americans felt. There was outrage, there was talk of pulling them down, or outlawing them. But it didn't matter, we still watched, because we knew it was all true.
                            </p>
                            <p>One might think that this is a tale of collapse. But this is only the beginning of the story. This story does indeed have a happy ending, because when death went viral we could no longer hide from it. We learned first hand from the messages of the deceased that we all struggle and that life is exactly what we make of it. Suddenly, keeping death hidden in the backroom seemed absurd. We hit a tipping point where we couldn’t pretend to be indestructible, we couldn’t say ‘life is fragile’ with a wink and a smile as we tried to live forever. Life was fragile and death was everywhere. And because of this, we didn’t have much choice. We embraced it.
                            </p>
                            <p>Our attitudinal shift is only a doorway into this new world of death. And it definitely wasn't the only factor. How it manifests itself is really where the fun begins, in some ways it helped rescue humanity.</p>
                            <Divider />
                            <h4 className="trans-sub neon-title neon-sh">Where Technolgy Meets Green - Sylva Necropolis</h4>
                            <Divider />
                            <p>
                                Imagine… walking through a forest at dusk. Strands of light emanate from the treetops illuminating the path through the woods. There are benches scattered throughout the meandering path. In the distance,  ahead at 10 & 2, poking through the treetops are the upper halves of two tower-like structures. Architecturally they would appear to be a mash-up of the Emerald Tower from The Wizard of Oz and something from Blade Runner. Their outer lights pulse softly in the darkening sky calling out like a beacon to those who are visiting this particular  Sylva Necropolis, the 21st-century cemetery replacement. </p>
                            <p>Each tower serves a particular purpose. The larger tower, modeled loosely after Japan’s Ruridan, is a technological marvel. Standing over 400 feet tall, it can house hundreds of thousands of remains. The great hall, where most remains are stored would have been a technological marvel in another time. Small alcoves, each bathing in warm psychedelic light, and housing the urn of a human, stretch vertically hundreds of feet into the air. People can come here and, assuming they were given permission, access the remains of their loved ones, via private rooms which gather them via drone, and can then be taken back out onto the property for visitation or spreading. </p>

                            <p>
                                Tower two serves a different purpose. As the green movement went from being a luxury to a necessity due to climate change, body composting became the new gold standard for those wanting to die green. Wrapping and spiraling up around the outside of the tower is an epic ramp. Every Sunday, after a week preparing and mourning with the body at home with the now popular death doula, families carry the bodies of the deceased to the top of the tower and lower the shrouded body down over a fresh layer of dirt which has been laid out over the previous weeks' bodies. After this, the friends and family who carried the bodies, return to the bottom of the tower where they pull fully composted dirt out. This new dirt can be taken away by the family and spread anywhere they wish, many people use it in their home gardens. And while it contains none of their loved ones in actuality, it symbolizes death as a return to being a piece of everything.  This transformation of the body ritual is a symbol of the ultimate shift in death of America. Where we used to mourn alone and somber with only our closet friends and family we now have a communal ritual honoring all that have died. This weekly process has become in many ways the new American funeral. We no longer mourn our loss alone, we celebrate their return to the earth and all that they gave us together. Plus at the end of the day, it’s 2040 and we don’t quite lose them completely.
                            </p>
                            <Divider />
                            <h4 className="trans-sub neon-title neon-sh">Technology - A.I. as the First Afterlife</h4>
                            <Divider />
                            <p>Maybe I buried the lead (moderate pun intended) but in 2033 my brother lost his life. It was devastating. And while things have changed radically, death of a loved one is still devastating. But those moments of infinite sadness, where the only thing in the world that can console us is to hear the voice of a loved one who has died, those moments are different now. Before he died my brother chose to use a service to let pieces of him remain on. When I need to hear his voice I just ask Google, and because of the level of technology I can either listen back to actual conversations we had that were recorded or I can actually conversate with his A.I. and as much as I loved him, it’s great to get to still have him as a business partner but not actually have to take his advice. </p>
                            <Divider />
                            <h4 className="trans-sub neon-title neon-sh">In Death We Join</h4>
                            <Divider />
                            <p>Our fear of death held us back. Today there are no more exorbitant end of life medical costs because we have learned to let go. Grieving isn’t something we do alone, it is something we share with our community. And dying isn’t the end of our loved ones, they do not just live on in our heart, they are everywhere and can be anywhere we want them. We don't prepare our bodies in the dark for the ground we take them proudly and prepare them for their return to the earth. It’s wild when you stop to think about it, death was always a trip that we had to go on alone, but today, in many ways, it’s the thing that glues us together, in life and in death. </p>
                    </div>
                </div>
            </div>
        )
    }
}


export default TransformationPage
